<template>
    <table class="divide-y divide-gray-200 table-auto bg-white w-full">
        <thead class="">
        <tr class="">
            <td v-if="showSelect" class="w-8 px-6 rounded-tl-md py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                <slot name="selectHeader">
                    Fallbact for select
                </slot>
            </td>
            <th
            
            class="px-6 py-3 text-left text-xs min-w-[150px] font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
            v-for="(column, index) in columns"
            :class="
                [index === 0 ? 'rounded-tl-md' : '',
                index === columns.length - 1 ? '' : '']
            "
            :key="column.key"
            >
            {{ column.label }}
            </th>
        </tr>
        </thead>
        <tbody class="bg-white">
        <tr
            class="cursor-pointer hover:bg-gray-50 group m-0 p-0 h-full"
            :class="[
              false ? 'bg-blue-50 border-blue-500' : '',
              index === allRows.length - 1 ? '' : 'border-b',
              checkedRowIds?.includes(row.id) ? 'bg-blue-50' : 'bg-white'
            ]"
            v-for="(row, index) in allRows"
            :key="row.id"
            @mouseenter="hoveredRow = index"
            @mouseleave="hoveredRow = null"
            @click.stop="rowClicked(row.id)"
            ref="tableRows"
        >
            <td v-if="showSelect" :class="index === allRows.length-1 ? '' : ''" class="w-8 px-6 py-4 whitespace-nowrap font-normal text-sm text-gray-500">
              <slot name="selectRow" :row="row">
                Fallback for select
              </slot>
            </td>
            <td
            class="px-6 py-4 whitespace-nowrap font-normal text-sm text-gray-500"
            v-for="(column, cindex) in columns"
            :class="
                [cindex === 0 ? 'rounded-bl-md' : '',
                cindex === columns.length - 1 ? 'rounded-br-md' : '']
            "
            :key="column.key" >
            <slot :name="`${column.key}-data`" :row="row" :ind="index">
                <div class="relative">
                {{ row[column.key] }}
                </div>
            </slot>
            </td>
            <transition name="fade">
            <td class="absolute right-0" v-show="hoveredRow === index">
              <div v-if="!row.hideActions" @click.stop="toggleActionDropdown(row.id)" v-click-outside="closeActionDropdown" class="pl-6 pr-5 -mr-[1px] flex items-center justify-center -mt-[1px] bg-gradient-to-r from-white/0 via-white to-white bg-opacity-50" :class="index === allRows.length-1 ? `rounded-br-md` : ''" :style="{ height: `${heights[index]}px` }">
                <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M14.5 4C14.5 5.38071 13.3807 6.5 12 6.5C10.6193 6.5 9.5 5.38071 9.5 4C9.5 2.61929 10.6193 1.5 12 1.5C13.3807 1.5 14.5 2.61929 14.5 4Z" fill="#000000"></path> <path d="M14.5 12C14.5 13.3807 13.3807 14.5 12 14.5C10.6193 14.5 9.5 13.3807 9.5 12C9.5 10.6193 10.6193 9.5 12 9.5C13.3807 9.5 14.5 10.6193 14.5 12Z" fill="#000000"></path> <path d="M12 22.5C13.3807 22.5 14.5 21.3807 14.5 20C14.5 18.6193 13.3807 17.5 12 17.5C10.6193 17.5 9.5 18.6193 9.5 20C9.5 21.3807 10.6193 22.5 12 22.5Z" fill="#000000"></path> </g></svg>
                <div v-show="row.showActionDropdown">
                    <div class="flex flex-row space-x-4 items-center justify-center">
                    <transition
                        enter-active-class="transition ease-out duration-100"
                        enter-class="transform opacity-0 scale-95"
                        enter-to-class="transform opacity-100 scale-100"
                        leave-active-class="transition ease-in duration-75"
                        leave-class="transform opacity-100 scale-100"
                        leave-to-class="transform opacity-0 scale-95">
                        <div
                        class="z-20 origin-top-right absolute mr-32 mb-2 mt-14 w-36 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none text-gray-700 text-sm"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="user-menu-button"
                        tabindex="-1">
                            <slot name="actions" :row="row"> Fallback </slot>
                        </div>
                    </transition>
                    </div>
                </div>
              </div>
            </td>
          </transition>
        </tr>
        </tbody>
    </table>
</template>

<style scoped>
  .shadow-inner-right {
    box-shadow: inset -20px 0 10px -10px rgba(0, 0, 0, 0.04);
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>

<script setup>
import { ref } from "vue";

const props = defineProps({
  columns: {
    type: Array,
    required: true,
  },
  rows: {
    type: Array,
    required: true,
  },
  showSelect: {
    type: Boolean,
    required: true,
  },
  checkedRowIds: {
    type: Array,
    required: false,
    default: () => [] // ✅ Add this
  }
});

const tableRows = ref([])
const heights = ref([])
const hoveredRow = ref(null);
const emit = defineEmits(["rowClicked"]);
const allRows = ref(props.rows?.map(row => ({
  ...row,
  showActionDropdown: false
})));

watch(() => props.rows, async (newRows) => {
    allRows.value = newRows?.map(row => ({
      ...row,
      showActionDropdown: false
    }));

    await nextTick();
    heights.value = tableRows.value.map(el => el?.getBoundingClientRect().height || 0);
  },
  { immediate: true, deep: true }
);

const toggleActionDropdown = (id) => {
  allRows.value.forEach((ns, index) => {
    if (ns.id === id) {
      allRows.value[index].showActionDropdown =
        !allRows.value[index].showActionDropdown;
    } else {
      allRows.value[index].showActionDropdown = false;
    }
  });
};

const closeActionDropdown = () => {
  allRows.value.forEach((ns, index) => {
    allRows.value[index].showActionDropdown = false;
  });
};

const rowClicked = (id) => {
  emit("rowClicked", id)
}

onMounted(() => {
  nextTick(() => {
    heights.value = tableRows.value.map(el => el?.getBoundingClientRect().height || 0);
  });
})
</script>
